import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/user_details.png';

const Content = () => {
  return (
    <div>
      <p>
        From now on, you can get more information about the user by clicking on the name or ID on
        the sessions&apos; list. It is an easier way to find other user&apos;s sessions or to get
        aggregated information about the numer of sessions and duration.
      </p>
      <ImageModal src={img} alt="User details" title="Get more informations about the user" />
      <p className="mt-3">
        You can also apply this user as a filter by clicking &quot;Show All&quot; or &quot;Show
        matching filters&quot;.
      </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'User details',
  url: '/blog/user-details/',
  description: "More detailed user's view",
  author,
  img,
  date: '2019-06-25',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
